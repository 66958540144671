import React from "react"
import styled from "styled-components"

import { RESPONSIVE_STYLES } from "../../../AppConst"

export const ButtonWrapper = styled.div`
  max-width: 440px;
  margin: 48px auto 0px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    max-width: 280px;
    margin: 24px auto 0px;
  }
`