import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

const ImageWrapper = styled.div`
  width: 240px;
  @media screen and (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    width: 120px;
  }
`

const MemberImage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 460) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) { return null; }
      return (
        <ImageWrapper>
          <Img fluid={image.node.childImageSharp.fluid} alt={props.alt} />
        </ImageWrapper>
      );
    }}
  />
)
export default MemberImage