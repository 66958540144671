import React from "react"
import styled from 'styled-components';
import { Link } from 'gatsby';
import { RESPONSIVE_STYLES } from "../../../AppConst"

/**
 * Material
 */
import MemberImage from "../common/MemberImage"

/**
 * Element & Styles
 */
const Node = styled.div`
  width: ${(RESPONSIVE_STYLES.MAX_WIDTH - 40 * 2)/3}px;
  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width: calc((100% - 40px)/2);
  }
  @media screen and (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    width: calc((100% - 24px)/2);
  }
`
const MemberItemInner = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-decoration: none;
  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 0 0 8px;
    gap: 16px;
    img {
      width: 100%;
      height: 100%;
    }
    &:after {
      width: 40px;
    }
  }
  img {
    /* filter: grayscale(1); */
    transition: .3s!important;
    @media screen and (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
      width: 120px !important;
    }
  }
  &:hover {
    img {
      transform: scale(1.1) !important;
    }
  }
`
const MemberImageAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 320px;
  aspect-ratio: 1/1;
  box-sizing: border-box;
  /* overflow: hidden; */
  background: linear-gradient(to top, white 0%, white 15%, #f4f4f4 15%, #f4f4f4 85%, white 85%, white 100%);
  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width: 100%;
  }
  @media screen and (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    width: 100%;
    height: 176px;
  }
`
const MemberTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const MemberTitle = styled.h2`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const MemberTitleName = styled.span`
  display: block;
  margin-top: 0;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #222222;
  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 1.4rem;
    line-height: 2.1rem;
    letter-spacing: 0.7px;
  }
`
const MemberTitlePosition = styled.span`
  display: block;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #909090;
`

const ImageWrapper = styled.div`
  height: 400px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  .gatsby-image-wrapper {
    overflow: unset !important;
  }
  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    height: 360px;
  }
  @media screen and (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    height: 216px;
  }
`

/**
 * Types
 */
type State = {
  position: string
  name: string
  filename: string
  alt: string
  pathname: string
  color: string
}
type Props = State

/**
 * Component
 */
export const MemberItem: React.FC<Props> = props => {
  return (
    <Node>
      <MemberItemInner color={props.color} to={`/member/${props.pathname}`}>
        <ImageWrapper>
          <MemberImageAreaWrapper>
            <MemberImage filename={props.filename} alt={props.alt}/>
          </MemberImageAreaWrapper>
        </ImageWrapper>
        <MemberTitleWrapper>
          <MemberTitle>
            <MemberTitleName>{props.name}</MemberTitleName>
            <MemberTitlePosition>{props.position}</MemberTitlePosition>
          </MemberTitle>
        </MemberTitleWrapper>
      </MemberItemInner>
    </Node>
  )
}
