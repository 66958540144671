import React from 'react'
import styled from 'styled-components'

import { RESPONSIVE_STYLES } from '../../../AppConst'
import { RoundLinkButton } from "../../common/RoundLinkButton"

import { ButtonWrapper } from '../common/ButtonWrapper'

const Wrapper = styled.div`
  width: 100%;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: #222222;
    text-align: center;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
    }
  }
`

export const Application = () => {
  return (
    <Wrapper>
      <p>ネクストライブに興味をお持ちいただきありがとうございます。<br />ネクストライブがあなたの求める環境かどうか確かめていただくために、<br />カジュアルにお話しする場をご用意しています。<br />ぜひお気軽にお申込みください！</p>
      <ButtonWrapper>
        <RoundLinkButton title='カジュアル面談に申し込む' url='/contact/casualvisit' color='#EFEFEF'/>
      </ButtonWrapper>
    </Wrapper>
  )
}
