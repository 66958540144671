import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { RESPONSIVE_STYLES } from "../../AppConst"

import IconTriangle from "../../images/icon_triangle.svg"

type Props = {
  title: string,
  url: string,
  height?: number,
  target?: string,
  isLp?: boolean,
  className?: string,
}

const Node = styled.div<{height: number}>`
  display: flex;
  width: 100%;

  & > a {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    height: ${props => props.height}px;
    margin: auto;
    text-decoration: none;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #FFFFFF;

    background-color: #222222;
    text-align: left;
    transition: .3s;
    border: 2px solid #FFFFFF;

    &:after {
      content: '';
      position: absolute;
      right: 24px;
      mask-image: url(${IconTriangle});
      width: 24px;
      height: 24px;
      background-color: #FFFFFF;
      transition: .3s;
    }

    &:hover {
      background-color: #FFFFFF;
      color: #222222;
      border: 2px solid #222222;

      &:after {
        right: 12px;
        background-color: #222222;
      }
    }
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      height: ${props => (props.height * 0.75)}px;
      padding: 0 24px; // 元: 18px 24px
      font-size: 1.4rem;
      line-height: 2.1rem;
    }

    & > .br {
      display: block;

      &__tb {
        display: none;
        @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
          display: block;
        }
      }

      &__sp {
        display: none;
        @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
          display: block;
        }
      }
    }
  }
`

const Inner = styled.div<{width: string}>`
  width: ${props => props.width}px;
`


export const RoundLinkButton: React.VFC<Props> = ({title, url, height=70, target="_self", isLp=false, className='' }) => {

  const Button = styled(Link)<{height: number}>`

  `

  return (
  <Node height={height} >
    {isLp ? (
        <a href={url} target={target} className={className} dangerouslySetInnerHTML={{__html: title}} />
      ) : (
        <Link to={url} target={target} className={className} dangerouslySetInnerHTML={{__html: title}} />
      )
    }
  </Node>
  )
}
