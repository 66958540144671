import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby';

import { RESPONSIVE_STYLES } from '../../../AppConst'
import { TitleAndCaption } from '../common/TitleAndCaption'
import { RoundLinkButton } from '../../common/RoundLinkButton';

import { ButtonWrapper } from '../common/ButtonWrapper';

import IconExclamation from "../../../images/recruit/icon_exclamation.svg"
import { PickUpMemberContainer } from '../../member/common/PickUpMemberContainer';
import { SpWrapper } from '../../common/SpWrapper';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: auto;
  padding: 160px 20px 160px;
  box-sizing: border-box;

  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  color: #222222;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 80px 0;
  }

  & .headline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    margin: 96px auto 0;

    font-size: 24px;
    line-height: 36px;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      margin-top: 48px;
      font-size: 16px;
      line-height: 32px;
    }
    & ::before {
      content: "";
      position: relative;
      width: 40px;
      height: 0px;
      border: 2px solid #222222;
      transform: rotate(60deg);
    }
    & ::after {
      content: "";
      position: relative;
      width: 40px;
      height: 0px;
      border: 2px solid #222222;
      transform: rotate(-60deg);
    }
  }
  & .lead {
    margin-top: 80px;
    font-size: 16px;
    line-height: 32px;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      margin-top: 16px;
      font-size: 14px;
      line-height: 28px;
    }
  }
`

const ReqestContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  width: 100%;
  margin: 40px auto 0;
  padding: 24px 40px;
  box-sizing: border-box;

  background-color: #E9E9E9;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    flex-direction: column;
    gap: 8px;
    padding: 30px 24px 24px;
  }

  & > img {
    width: 54px;
    height: 54px;
  }

  & > p {
    margin: 0;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    color: #222222;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 14px;
      line-height: 28px;
    }
  }
`

// TODO: #470 対応時にリファクタリング
const recruitMember = [
  `maiki`,
  `finako`,
  `tetsuokumura`,
  `r`,
]

export const PersonInCharge:React.VFC = () => {
  const { site: { siteMetadata: { member } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          member {
            name
            position
            pathname
            alt
            member_index_filename
            displayed_member
            color
          }
        }
      }
    }
  `);

  const sortMember = member.filter(({ pathname }) => recruitMember.includes(pathname));

  const personInCharge = sortMember.sort((x, y) => {
    return recruitMember.indexOf(x.pathname) - recruitMember.indexOf(y.pathname)
  });

  return (
    <SpWrapper>
      <Wrapper>
        <TitleAndCaption title='カジュアル面談の担当者' subTitle='PERSON IN CHARGE'/>
        <h2 className='headline'>私たちがお話させていただきます</h2>
        <PickUpMemberContainer members={personInCharge}/>
        <ReqestContainer>
          <img src={IconExclamation} />
          <p>「同世代のエンジニアと話したい」、「幅広く話を聞きたい」、「経営陣と話したい」などのご希望に応じて、個別にメンバーをセッティングすることが可能です。ぜひご希望をお聞かせください。
          </p>
        </ReqestContainer>

        <div className='lead'>
          <p>カジュアル面談は採用選考に影響するものではありません。<br/>
                お気軽にお申込みください。
          </p>
          <ButtonWrapper>
            <RoundLinkButton title='カジュアル面談に申し込む' url='/contact/casualvisit'/>
          </ButtonWrapper>
        </div>
      </Wrapper>
    </SpWrapper>
  )
}


