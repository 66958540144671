import React from "react"
import styled from 'styled-components';
import { RESPONSIVE_STYLES } from "../../../AppConst"

/**
 * Material
 */
import { MemberItem } from "./MemberItem";

/**
 * Element & Styles
 */
const MemberContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0 40px;
  /* margin: 40px 0 0; */
  box-sizing: border-box;
  div:nth-child(n+4) {
    display: none;
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    /* gap: 40px 40px; */
    div:nth-child(4) {
      display: block;
    }
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 0 23px;
  }
`

/**
 * Types
 */
type State = {
  members: any

}
type Props = State

/**
 * Component
 */
export const PickUpMemberContainer: React.FC<Props> = props => {
  return (
    <MemberContainer>
      {props.members.map((item, index) => {
        return <MemberItem
                          key={index}
                          position={item.position}
                          name={item.name}
                          filename={item.member_index_filename}
                          pathname={item.pathname}
                          color={item.color}
                          alt={item.alt} />
      })}
    </MemberContainer>
  )
}
