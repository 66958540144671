import React from 'react'
import Layout from "../../../template/Layout"
import Head from "../../../head"
import styled from "styled-components"
import Img from "gatsby-image"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { ogpUrl } from "../../../utlis/ImageUtil"

import { Application } from './Application'
import { Recommended } from './Recommended'
import { Flow } from './Flow'
import { PersonInCharge } from './PersonInCharge'

import { SectionTitle } from "../../common/SectionTitle"
import { PickUpArticleList } from "../common/PickUpArticleList"
import { Props as BreadcrumbsData } from "../../common/Breadcrumbs"

const Wrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`

const ImageWrapper = styled.div`
  margin-top: 96px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin-top: 48px;
  }
`

const ApplicationContainer = styled.div`
  margin: 80px 0;
  padding: 0 20px 0;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin: 40px 0;
  }
`

const RecommendedContainer = styled.div`
  background-color: #222222;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
  }
`

const FlowContainer = styled.div`
  padding: 160px 0;
  box-sizing: border-box;
  background-color: #E9E9E9;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 16px;
  }
`

const PersonInChargeContainer = styled.div`
  background-color: #FFFFFF;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
  }
`

const BlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 160px 20px;
  background-color: #E9E9E9;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 16px;
  }
`

const PickUpArticleListWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
`

type Props = {
  headerImg?: any;
  breadcrumbsData: BreadcrumbsData["values"];
}

export const CasualVisit: React.FC<Props> = (props) => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="カジュアル面談 | 採用情報 | ネクストライブ株式会社"
        description="ネクストライブ株式会社のカジュアル面談の紹介ページです。"
        img={ogpUrl(process.env.NODE_ENV, props.headerImg)}
      />
      <Wrapper>
        <SectionTitle title="CASUAL VISIT" subTitle="カジュアル面談"/>
        <ImageWrapper>
          <Img fluid={props.headerImg} alt="カジュアル面談"/>
        </ImageWrapper>

        <ApplicationContainer>
          <Application/>
        </ApplicationContainer>
        <RecommendedContainer>
          <Recommended/>
        </RecommendedContainer>
        <FlowContainer>
          <Flow/>
        </FlowContainer>
        <PersonInChargeContainer>
          <PersonInCharge/>
        </PersonInChargeContainer>
        <BlogContainer>
          <PickUpArticleListWrapper>
            <PickUpArticleList/>
          </PickUpArticleListWrapper>
        </BlogContainer>
      </Wrapper>
    </Layout>
  )
}
