import styled from "styled-components"

import { RESPONSIVE_STYLES } from "../../../AppConst"

export const FlowWrapper = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 40px auto 0;

  & .title {
    margin: 0 0 40px;
    padding: 16px 0;

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    text-transform: capitalize;

    color: #FFFFFF;
    background: #222222;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 16px;
      line-height: 24px;
    }
  }
`
