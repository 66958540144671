import React from 'react'
import styled from 'styled-components'

import { TitleAndCaption } from '../common/TitleAndCaption'
import { FlowContainer } from '../common/FlowContainer'
import { FlowExplanation } from './FlowExplanation'
import { FlowWrapper } from '../common/FlowWrapper'

import { RESPONSIVE_STYLES } from '../../../AppConst'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: auto;
  text-align: center;
`

const Container = styled.div`
  margin-top: 80px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin-top: 40px;
  }

  & .caption {
    max-width: 288px;
    margin: 16px auto 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
`

const FlowTextList = [
  {
    text: [
      `基本的には`,
      `オンラインで`,
      `実施いたします`,
    ],
  },
  {
    text: [
      `面談日程は`,
      `ご希望に応じて`,
      `調整いたします`,
    ],
    caption: [
      `※平日9:00開始 〜 20:00終了の枠でご都合の良い日程をお知らせください`
    ]
  },
  {
    text: [
      `所要時間は`,
      `30分程度です`,
    ],
  }
]

const FlowItemList = [
  {
    number: `01`,
    title: `アイスブレイク　5分`,
    detail: [ `お互いの自己紹介や軽い雑談`, ]
  },
  {
    number: `02`,
    title: `ネクストライブの会社説明　15分`,
    detail: [ `会社のミッション・ビジョン・バリュー、事業内容、社風や職場環境、どのような人材を求めているか等、詳しくご説明します。`, ]
  },
  {
    number: `03`,
    title: `質問タイム　10分～30分`,
    detail: [`会社ついて知りたいこと・気になることを、自由に質問してください！
    職場の雰囲気や働いている人・技術・業務の内容・キャリアについてなど、気軽なことから面接では聞きにくいことまでなんでも聞いていただければと思っています。`,]
  },
]

export const Flow = () => {
  return (
    <Wrapper>
      <TitleAndCaption title='カジュアル面談の流れ' subTitle='FLOW' />
      <Container>
        <FlexContainer>
          {FlowTextList.map((item, idx) => {
            return (
              <FlowExplanation
                key={idx}
                text={item.text}
                caption={item.caption} />
            )
          })}
        </FlexContainer>

        <FlowWrapper>
          <h3 className='title'>カジュアル面談の内容</h3>
          {FlowItemList.map((item, idx) => {
            const { number, title, detail } = item;
            return (
              <React.Fragment key={idx}>
                <FlowContainer
                  number={number}
                  title={title}
                  detail={detail}/>
              </React.Fragment>
            )
          })}
        </FlowWrapper>
      </Container>
    </Wrapper>
  )
}
