import React from 'react'
import styled from 'styled-components'

import { RESPONSIVE_STYLES } from '../../../AppConst'

const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: ${(props) => props.color};
  margin-block: initial;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 24px;
    line-height: 36px;
  }
`

const SubTitle = styled.p`
  font-family: 'Righteous';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #61B1C1;
  margin-block: initial;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 12px;
    line-height: 24px;
  }
`

const Supplemental = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.6px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  }
`

type Props = {
  title: string
  subTitle: string
  supplemental?: string
  color?: string
}

export const TitleAndCaption: React.VFC<Props> = (props) => {
  const { supplemental = null, color = "#222222" } = props;

  return (
    <>
      {
        props.supplemental && <Supplemental>{props.supplemental}</Supplemental>
      }
      <Title color={color}>{props.title}</Title>
      <SubTitle>{props.subTitle}</SubTitle>
    </>
  )
}
