import React from 'react'
import styled from 'styled-components'

import { RESPONSIVE_STYLES } from '../../../AppConst'

const Wrapper = styled.div`
  width: 100%;
  padding: 24px 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 21px;

  border-bottom: 1px solid #222222;

  &:first-of-type {
    padding: 0 0 16px;
  }

  & .content-number {
    font-family: 'Righteous';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #222222;

    &[data-strong='true'] {
      color: #DBAAC8;
    }

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 24px;
      line-height: 24px;
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: #222222;
  text-align: left;

  & .content-title {
    margin: 0;

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-transform: capitalize;
    color: #222222;

    &[data-strong='true'] {
      color: #DBAAC8;
    }

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 16px;
      line-height: 24px;
    }
  }
  & .content-detail {
    margin: 0;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #222222;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 14px;
      line-height: 28px;
    }
  }
`

type Props = {
  number?: string;
  title?: string;
  detail?: string[];
  strongIndex?: number;
  index: number;
}

export const FlowContainer:React.VFC<Props> = (props) => {
  const { number, title, detail, strongIndex = 4 } = props
  return (
    <Wrapper data-strong={props.index === strongIndex}>
      <span className='content-number' data-strong={props.index === strongIndex}>{number}</span>
      <TextContainer>
        <h3 className='content-title' data-strong={props.index === strongIndex}>{title}</h3>
        {detail.map((item, idx) => {
          return <p key={idx} className='content-detail'>{item}</p>
        })}
      </TextContainer>
    </Wrapper>
  )
}
