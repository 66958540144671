import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../AppConst"
import { TriangleSwitch } from "./TriangleSwitch"

const Node = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: auto;

  &:hover {
    & .title{
      color: #61B1C1;
    }
    & .mark {
      right: -15px;
      border-color: transparent transparent transparent #61B1C1;
    }
    & .mark svg {
      fill: #61B1C1;
    }
  }
`

const Title = styled(Link)`
  margin-right: 20px;
  color: ${props => props.color};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 3.6rem;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  opacity: 1;
  transition: .3s;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    line-height: 3rem;
  }
`

const TriangleWrapper = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  top: auto;
  right: 0;
  bottom: auto;
  transition: .3s;
  transform: rotate(-90deg);
`

export type Props = {
  url: string,
  darkMode?: boolean,
  text: string,
}

export const ReadMore: React.VFC<Props> = ({url, darkMode = true, text}: Props) => {
  const color = darkMode ? '#222222' : '#ffffff';
  return (
    <Node>
      <Title className="title" to={url} color={color}>{text}</Title>
      <TriangleWrapper className="mark">
        <TriangleSwitch color={color} />
      </TriangleWrapper>
    </Node>
  )
}
