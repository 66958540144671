import React from "react"
import { graphql } from "gatsby"
import { CasualVisit } from "../../../components/recruit/casual_visit"

const breadcrumbsData: { text: string; path: string }[] = [
    { text: `RECRUIT`, path: `recruit` },
    { text: `カジュアル面談`, path: `recruit/casualvisit` },
]

export default function CasualVisitPage({ data }) {
  const {
    headerImg,
  } = data
  return (
    <CasualVisit
        breadcrumbsData={breadcrumbsData}
        headerImg={headerImg.childImageSharp.fluid}/>
  )
}

export const query = graphql`
  query CasualVisitQuery {
    headerImg: file(relativePath: { eq: "images/recruit/img_recruit_casual_visit_top.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`