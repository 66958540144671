import React from 'react'
import styled from 'styled-components'

import { RESPONSIVE_STYLES } from '../../../AppConst'

import checkBox from "../../../images/recruit/icon_checkBox.svg"

const Wrapper = styled.div`
  width: 100%;
  padding: 160px 20px;
  box-sizing: border-box;

  font-weight: 700;
  text-align: center;
  box-sizing: border-box;
  text-transform: capitalize;
  color: #222222;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 16px;

    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
  }

  & .Title {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 24px;
      line-height: 36px;
    }
  }
  & .bottom-message {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
    }
  }
`

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;

  background: #FFFFFF;
  width: 100%;
  max-width: 960px;

  margin: 40px auto;
  padding: 40px;
  box-sizing: border-box;

  & .recommended-text {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    min-width: 428px;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      min-width: auto;
    }

    img {
      width: 24px;
      height: 24px;
    }

    p {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #222222;
      text-align: left;
    }
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    flex-direction: column;
    max-width: 100%;
    padding: 24px;
  }
`

const RecommendedList:string[] = [
  `まだ転職は考えていないけど、少し興味があるから情報収集しておきたい`,
  `実際に働いている人の話を聞いてみたい`,
  `気になるけど選考に進むか悩んでいる`,
  `仕事の内容をもっと詳しく聞きたい`,
  `オフィスを見学したい`,
]

export const Recommended:React.VFC = () => {
  return (
    <Wrapper>
      <h3 className='Title'>カジュアル面談はこんな方にオススメ！</h3>
      <ListWrapper>
        {RecommendedList.map((item, idx) => {
          return(
            <div key={idx} className='recommended-text'>
              <img src={checkBox} alt="checkBox"/>
              <p>{item}</p>
            </div>
          )
        })}
      </ListWrapper>
      <p className='bottom-message'>少しでもご興味をお持ちいただいている方は、ぜひお話しましょう!</p>
    </Wrapper>
  )
}
