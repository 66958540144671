import React from 'react'
import styled from 'styled-components'

import { RESPONSIVE_STYLES } from '../../../AppConst'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width: 100%;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 330px;
  min-height: 160px;
  background: #FFFFFF;
  box-sizing: border-box;
  text-align: center;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    min-height: inherit;
    padding: 24px 0;
  }

  & .text {
    margin: 0;

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    text-align: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #222222;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const TextContainer = styled.div`
  width: 330px;
  margin-top: 16px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width: 100%;
  }

  & p {
    margin: 0;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #222222;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      text-align: left;
    }
  }
`

type Props = {
  text: string[];
  caption?: string[];
}

export const FlowExplanation:React.VFC<Props> = ({text, caption}) => {
  return (
    <Wrapper>
      <Container>
        {text.map((item, idx) => {
          return (
            <span key={idx} className='text'>{item}</span>
          )
        })}
      </Container>
      {
        caption &&
          <TextContainer>
            {caption?.map((item, idx) => {
              return (
                <p key={idx}>{item}</p>
                )
              })
            }
          </TextContainer>
      }
    </Wrapper>
  )
}
